import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Divider } from '@mui/material';

export default function NavBar() {
  return (
    <AppBar position="static" sx={{backgroundColor: "#532ee6"}}>
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>engraphō</Typography>
        
        <Divider orientation="vertical" sx={{backgroundColor: "#ffffff", margin: "10px"}} flexItem />

        <Button component={Link} to="/" color="inherit">Home</Button>
        <Button component={Link} to="/templates" color="inherit">Templates</Button>
        <Button component={Link} to="/pricing" color="inherit">Pricing</Button>
        <Button component={Link} to="/support" color="inherit">Support</Button>

        <Divider orientation="vertical" sx={{backgroundColor: "#ffffff", margin: "10px"}} flexItem />

        <Button component={Link} to="/login" color="inherit">Log In</Button>
        <Button component={Link} to="/signup" sx={{backgroundColor: "#9c89e8", color: "#ffffff"}}>Sign Up</Button>
      </Toolbar>
    </AppBar>
  );
}

